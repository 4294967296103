import PropTypes from 'prop-types'
import React from 'react'

const Avatar = ({ alt, src }) => (
  <div
    className="
      bg-blue-900
      flex
      h-20
      items-center
      justify-center
      mt-2
      rounded-full
      w-20
    "
  >
    <img
      alt={alt}
      className="
        h-16
        rounded-full
        w-16
      "
      src={src}
    />
  </div>
)

Avatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
}

Avatar.defaultProps = {
  alt: 'Default Portrait Photo',
  src: 'https://podlovers.org/assets/static/2b25a560dc14790cc28ddab36c41e91c.e41a6e4.2b25a560dc14790cc28ddab36c41e91c.jpg',
}

export default Avatar