import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Layout from '../components/layout'

import Avatar from '../elements/avatar'
import Headline from '../elements/headline'
import Animation from '../elements/animation'

import { PODLOVERS } from '../helpers/constants'

export default function BlogArticleTemplate({ data }) {
  const { markdownRemark } = data
  const { excerpt, frontmatter, html } = markdownRemark

  return (
    <Layout>
      <section
        className="
          bg-blue-100
          flex
          flex-col
          items-center
          justify-center
          mt-16
          px-16
          py-48
          text-center
          w-full
        "
      >
        <p
          className="
            font-mono
            text-blue-900
          "
        >Posted on {frontmatter.date} by {frontmatter.author}</p>
        <Headline level={1}>{frontmatter.title}</Headline>
        <Headline level={3}>{excerpt}</Headline>
        <Avatar
          alt={`Portrait of ${frontmatter.author}`}
          src={`/images/${frontmatter.authorImage}`}
        />
      </section>
      <section
        className="
          flex
          flex-wrap
          items-stretch
          justify-center
          px-4
          md:px-0
        "
      >
        <Animation />
        <div
          className="
            markdown
            ml-auto
            mr-auto
            mt-16
            max-w-2xl
            z-10
          "
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </section>
      <section
        className="
          border-gray-200
          border-b-2
          border-t-2
          flex
          items-center
          ml-auto
          mr-auto
          my-16
          px-4
          md:px-0
          py-12
          max-w-2xl
          w-full
        "
      >
        <div
          className="
            hidden
            md:static
          "
        >
          <Avatar
            alt={`Portrait of ${frontmatter.author}`}
            src={`../images/${frontmatter.authorImage}`}
          />
        </div>
        <div
          className="
            ml-2
            md:ml-10
          "
        >
          <p
            className="
              font-bold
              text-md
              md:text-lg
            "
          >{frontmatter.author}</p>
          <p
            className="
              font-mono
              text-blue-900
            "
          >{PODLOVERS[frontmatter.author].role}</p>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        author
        authorImage
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`

BlogArticleTemplate.propTypes = {
  data: PropTypes.object,
}
