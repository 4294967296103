export const PODLOVERS = {
  'Alexander Heimbuch': {
    role: 'Frontend Developer',
  },
  'Eric Teubert': {
    role: 'Backend Developer',
  },
  'Michaela Lehr': {
    role: 'Frontend Developer',
  },
  'Tim Pritlove': {
    role: 'Podcaster & Podlove Evangelist',
  },
}